<template>
  <v-app id="updatePassword">
    <v-main
      class="login-panel"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container class="pt-15">
        <v-snackbar v-model="snackbar" top right color="error">
          {{ textError }}
        </v-snackbar>
        <v-row text-align="center" justify="center">
          <v-card class="updatePasswordform d-grid text-center align-center">
            <v-card-text v-if="isValid">
              <v-card-title
                class="
                  header-font
                  blue-grey--text
                  text--darken-2
                  font-weight-bold
                  pt-1
                "
              >
                Update Password
              </v-card-title>
              <v-form v-model="isFormValid" ref="updatePasswordForm">
              <loading :active="isLoading" :loader="loader" />
                <v-row
                  cols="12"
                  class="pt-5"
                  text-align="center"
                  justify="center"
                >
                  <v-text-field
                    :rules="passwordRule"
                    hint="Password Must be contain at least 8 characters, including at least 1 number , 1 alphabet in lowercase letters or uppercase letters and 1 special characters e.g #, ?, !"
                    v-model.trim="updatePasswordData.newPassword"
                    :append-icon-cb="() => (e1 = !e1)"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Password"
                    required
                    autocomplete="off"
                    label="Password"
                    outlined
                    shaped
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    trim="loginData.password"
                    @keyup.enter="doLogin"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-row>
                <v-row cols="12">
                  <v-text-field
                    :rules="confirmPasswordRules"
                    v-model.trim="updatePasswordData.newPasswordVerify"
                    :append-icon-cb="() => (e1 = !e1)"
                    :type="confirmPassword ? 'text' : 'password'"
                    placeholder="Confirm Password"
                    required
                    autocomplete="off"
                    label="ConfirmPassword"
                    outlined
                    shaped
                    :append-icon="confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    trim="loginData.confirmPassword"
                    @keyup.enter="doLogin"
                    @click:append="confirmPassword = !confirmPassword"
                  >
                  </v-text-field>
                </v-row>
              </v-form>
              <v-layout class="form-field">
                <v-flex>
                  <v-btn
                    color="primary"
                    class="px-0 mx-0 btn-loging"
                    block
                    @click="updatePassword"
                    :disabled="!isFormValid"
                    >UPDATE
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-else class="text-center">
              <h1 class="pb-4 display-1 error--text">Error</h1>
              <h3 class="pb-5">Link is not valid</h3>
              <v-btn color="info" @click="backToHome()">Back To Login</v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import backgroundUrl from "../../assets/images/didpanel.jpg";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      e1: true,
      isLoading: false,
      snackbar: false,
      textError: "",
      loading: false,
      loader: "bars",
      updatePasswordData: {},
passwordRule: [(v) => !!v || "Please enter the Password",
      (v) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          return (
            pattern.test(v) ||
            "Min. 8 characters with at least one capital letter, a number and a special character."
          );
        },
      ],
      confirmPasswordRules: [
        (value) => !!value || "Please enter confirm password",
        (value) =>
          value === this.updatePasswordData.newPassword ||
          "The password confirmation does not match.",
      ],

      isFormValid: false,
      queryParams: {},
      showPassword: false,
      confirmPassword: false,
      backgroundUrl: backgroundUrl,
      isValid: true,
    };
  },
  components: {
    Loading,
  },

  methods: {
    clear() {
      this.$refs.updatePasswordForm.reset();
    },

    async verifyPasswordRecoveryLink() {
      this.isLoading = true;
      try {
        let str = window.location.search.replace("?", "");
        let arr = str.split("&");

        for (let item of arr) {
          let split = item.split("=");
          this.queryParams[split[0]] = split[1];
        }

        let res = await authAPI.verifyPasswordRecoveryLink(this.queryParams);
        this.isLoading = false;
        this.isValid = res.linkValid;
      } catch (err) {
        this.isLoading = false;
      }
    },

    async updatePassword() {
      this.isLoading = true;
      let updatePasswordDetail = {
        userLoginId: this.queryParams.email.replace("%40", "@"),
        verifyHash: decodeURIComponent(this.queryParams.verifyHash),
        loggedIn: "N",
        newPassword: this.updatePasswordData.newPassword,
        newPasswordVerify: this.updatePasswordData.newPasswordVerify,
      };
      try {
        let res = await authAPI.updatePassword(updatePasswordDetail);
        this.isLoading = false;
        if (res) {
          // location.href = "/";;
          location.href = "/";
        }
      } catch (error) {
        this.isLoading = false;
        this.snackbar = true;
        this.textError = error.data.messageDetail;
        // this.$root.$emit("SHOW_SNACKBAR", {
        //   text: err.data.messageDetail,
        //   color: "error",
        // });
      }
    },
    backToHome() {
      location.href = "/";
    },
  },
  beforeMount() {
    this.verifyPasswordRecoveryLink();
  },
  mounted() {},
};
</script>



 <style scoped>
.updatePassword {
  padding-top: 200px;
}
.updatePasswordform {
  width: 38%;
  height: 350px !important;
  margin-top: 10%;
}

.btnloging {
  margin-bottom: 15px;
  margin-left: 15px;
}

.updatePasswordform {
  padding-top: 30px !important;
  padding: 10px;
}

#updatePassword {
  border: 5px solid black;
  border-end-start-radius: 50%;
}

.d-grid {
  display: grid;
}
</style>

